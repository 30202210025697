import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";

const ContinerWraper = styled(Container)`
  position: relative;
  height: auto;
  width: 100%;
  padding: 100px 0 0 !important;
`;

const StyledAccordion = styled(Accordion)`
  background: transparent !important;
  border-bottom: 1px solid #ffffff97;
  & :last-child{
    border-bottom: none !important;
  }
`;
const Summary = styled(AccordionSummary)`
  background: transparent !important;
`;
const Details = styled(AccordionDetails)`
  background: transparent !important;
`;
const FAQSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="faq">
      <Container>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            rowGap: "20px",
            zIndex: "1",
            columnGap: "30px",
          }}
        >
          <Grid item xs={12}>
            <SectionsHeading
              ta="left"
            >
              FREQUENTLY ASKED QUESTIONS
            </SectionsHeading>
          </Grid>
          <Grid item xs={12}>
            <StyledAccordion>
              <Summary
                expandIcon= {<ExpandMoreIcon sx={{color: 'white'}}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Paragraph fw='bold' fs='18px' ta='left'>Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing?</Paragraph>
              </Summary>
              <Details>
                <Paragraph  fs="14px" ta="left">
                  Shia Vault enables users to use their Shia tokens as
                  collateral to receive Ethereum tokens, up to 85% of the
                  collateral's value. This approach caters to users who want to
                  access liquidity without selling their Shia tokens. Users can
                  then loan out ETH, and when the time is right, repurchase
                  their Shia tokens using the borrowed ETH at the same initial
                  amount. The maximum period for this process is 6 months, and
                  there's a one-time flat fee at the time of locking, with no
                  interest involved.
                </Paragraph>
              </Details>
            </StyledAccordion>
            <StyledAccordion>
              <Summary
                expandIcon= {<ExpandMoreIcon sx={{color: 'white'}}/>}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Paragraph fw='bold' fs='18px' ta='left'>Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Unt Ut Labore Et Dolore Magna?</Paragraph>
              </Summary>
              <Details>
                <Paragraph  fs="14px" ta="left">
                  If you're optimistic about Shia's potential but still want to
                  maintain trading flexibility, Shia Vault offers a unique
                  solution. By using your Shia tokens as collateral, you can
                  unlock ETH liquidity without selling your Shai tokens. This
                  approach allows you to seize trading opportunities while
                  staying exposed to potential Shia token gains.
                </Paragraph>
              </Details>
            </StyledAccordion>
            <StyledAccordion>
              <Summary
                expandIcon= {<ExpandMoreIcon sx={{color: 'white'}}/>}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Paragraph ta="left" fw='bold' fs='18px'>
                Lorem Ipsum Dolor Sit Ame ?
                </Paragraph>
              </Summary>
              <Details>
                <Paragraph fs="14px" ta="left">
                  The maximum borrowing period is 6 months. If the borrowed ETH
                  is not repaid within this timeframe, the protocol may initiate
                  an automatic liquidation of your collateral to recover the
                  ETH. To avoid this, make sure to plan your repayment within
                  the specified period to retain your collateral and any price
                  appreciation it may have gained.
                </Paragraph>
              </Details>
            </StyledAccordion>
          </Grid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default FAQSection;
