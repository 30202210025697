import { Container, Grid } from '@mui/material'
import React from 'react'
import SupplyTable from '../../Components/Table'
import SectionsHeading from '../../MainComponent/SectionsHeading'

const TierSection = () => {
  return (
    <Container>
        <Container>
            <Grid container>
                <Grid item xs={12} sx={{p:"50px 0"}}>
                    <SectionsHeading>
                    Our Tier System
                    </SectionsHeading>
                </Grid>
                <Grid item xs={12}>
                    <SupplyTable></SupplyTable>
                </Grid>
            </Grid>
        </Container>
    </Container>
  )
}

export default TierSection