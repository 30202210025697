import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import Paragraph from "../../MainComponent/Paragraph";
import CustomButton from "../../MainComponent/CustomBtn";

const RowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 5px;
`;
const CoulmnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;
const TContainer = styled(TableContainer)`
  background-color: transparent !important;
  font-family: PoppinsB !important;
  color: #707070 !important;
  box-shadow :none;
`;
const TCell = styled(TableCell)`
    background: ${props => props.bgc ? props.bgc : "transparent"};
    font-size: ${props=> props.fs ? props.fs : "16px"};
    width:  "100%";
    max-width:  ${props=> props.mw ? props.mw: "130px"};
    font-family: ${props=> props.fm ? props.fm : "PoppinsR"};
    margin:  ${props=> props.m ? props.m : "0 0px"};
    padding:  ${props=> props.p ? props.p : "20px"};
    color:${props => props.c ? props.c : "#fff"};
    border: ${props => props.b ? props.b : "1px solid #1178F3"};
    text-align: ${props => props.ta ? props.ta : "center"};
    @media (max-width: 768px) {
      text-align: center;
      font-size: 14px;
    }
`;
function createData(Features, Tier1, Tier2, Tier3) {
  return { Features, Tier1, Tier2, Tier3 };
}

export default function SupplyTable() {
  const rows = [
    createData(
          "Holding","=>0.5% Supply","=>0.25% Supply","<0.25% Supply",
      
    ),
    createData(
      "Signals","First Priority","5 Min After Tier 1","No Signal",
    ),
    createData(
      "Reward Pool Share","60%","30%","10%",
    ),
  ];
  return (
    <TContainer component={Paper}>
      <Table sx={{width: '100%', minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TCell bgc="#1279F3" ta="left" fm="PoppinsSB" >Features</TCell>
            <TCell c="#1178F3" align="right">Tier 1</TCell>
            <TCell c="#1178F3" align="right">Tier 2</TCell>
            <TCell c="#1178F3" align="right">Tier 3</TCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              
            >
              <TCell ta="left" fm="PoppinsSB" bgc="#000" component="th" scope="row">
                {row.Features}
              </TCell>
              <TCell  align="right">{row.Tier1}</TCell>
              <TCell align="right">{row.Tier2}</TCell>
              <TCell align="right">{row.Tier3}</TCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TContainer>
  );
}
