import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import image1 from "../../../assets/Images/logo1.png";
import { ColumnGrid, Image } from "../../Styles/style";
import Paragraph from "../../MainComponent/Paragraph";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import CustomButton from "../../MainComponent/CustomBtn";

const ContinerWraper = styled(Container)`
  /* background: radial-gradient(circle at 50% 100%, #1177f37a, transparent 80%); */
  position: relative;
  height: auto;
  width: 100%;
  padding: 70px 0 0 !important;
`;

const Text = styled.input`
  background: transparent !important;
  border: 1px solid #1279F3;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  color: #fff;
  width: 100%;

  font-family: PoppinsR;
  :focus {
    outline: none;
    border-color: #fff;
  }
`;
const Textarea = styled.textarea`
  background: transparent !important;
  border: 1px solid #1279F3;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  color: #fff;
  font-family: PoppinsR;
  width: 100%;
  :focus {
    outline: none;
    border-color: #fff;
  }
`;
const Div = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px !important;
  @media (max-width: 899px) {
    align-items: start;
  }
`;

const Footer = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="howitworks">
      <Container>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            rowGap: "20px",
            zIndex: "1",
            columnGap: "30px",
            padding: "0 0 70px",
          }}
        >
          <Grid item xs={12} md={4}>
            <ColumnGrid ai="start">
              <Image mw="50px" src={image1} />
              <Paragraph p="0 0 0 5px"ta="left"  fs="14px" sx={{lineHeight:".9"}} >
                ALPHA <br />
                FUNDS
              </Paragraph>
            </ColumnGrid>

            <Paragraph p="10px 0 0" ta="left" fm="PoppinsB" fs="18px">
              Disclaimer:
            </Paragraph>
            <Paragraph p="0" ta="left" fs="14px">
              Please note that in your specific location, cryptocurrencies may
              lack regulation. The worth of these digital assets can be highly
              volatile, and there is a possibility of losing your entire
              investment. The website owner, administrators, and contributors
              shall not be held responsible for any losses, damages, or
              liabilities arising from the use of the information provided here.
              Additionally, any profits you make might be subjected to capital
              gains tax or other relevant taxes based on the laws in your
              jurisdiction.
            </Paragraph>
          </Grid>
          <Div item xs={12} md={3} sx={{paddingTop: '30px'}}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px !important",
              }}
            >
              <SectionsHeading
                ta="left"
                fs="16px"
                fs599="16px"
                color="#1279F3 !important"
              >
                Quick Links
              </SectionsHeading>
              <Link sx={{ textDecoration: "none" }} href="#home">
                <SectionsHeading
                  ta="left"
                  fs="14px"
                  fs599="14px"
                  p="5px 0"
                  p599="5px 0"
                  p899="5px 0"
                  fm="PoppinsR"
                >
                  About
                </SectionsHeading>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="#stats">
                <SectionsHeading
                  ta="left"
                  fs="14px"
                  fs599="14px"
                  p="5px 0"
                  p599="5px 0"
                  p899="5px 0"
                  fm="PoppinsR"
                >
                  Application Overview
                </SectionsHeading>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="#howitwork">
                <SectionsHeading
                  ta="left"
                  fs="14px"
                  fs599="14px"
                  p="5px 0"
                  p599="5px 0"
                  p899="5px 0"
                  fm="PoppinsR"
                >
                  Utility
                </SectionsHeading>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="#tokenomics">
                <SectionsHeading
                  ta="left"
                  fs="14px"
                  fs599="14px"
                  p="5px 0"
                  p599="5px 0"
                  p899="5px 0"
                  fm="PoppinsR"
                >
                  Whitepaper
                </SectionsHeading>
              </Link>
              
            </div>
          </Div>
          <Grid item xs={12} md={3} sx={{paddingTop: '30px'}}>
            <SectionsHeading
              ta="left"
              fs="16px"
              fs599="16px"
              
              color="#1279F3 !important"
            >
              Contact Us
            </SectionsHeading>
            <Text placeholder="Email" />
            <Textarea placeholder="Enter message here.." />
            <CustomButton mw="100%" minw="100%">Send</CustomButton>
          </Grid>
        </Grid>
      </Container>
      <div style={{ background: "#1279F3", width: "100%" }}>
        <SectionsHeading
          ta="center"
          fs="12px"
          fs599="12px"
          p="5px 0"
          p599="5px 0"
          p899="5px 0"
          fm="PoppinsR"
          color="#fff"
        >
         All rights reserved © 2023 Alpha Funds
        </SectionsHeading>
      </div>
    </ContinerWraper>
  );
};

export default Footer;
