import React from "react";
import { PageWrapper, PageWrapper1, PageWrapper2 } from "../../Styles/style";
import HeroSection from "../../Sections/S1-Header";
import FAQSection from "../../Sections/S8-FAQ";
import Footer from "../../Sections/S10-Footer";
import Comunity from "../../Sections/S9-Comunity";
import Partner from "../../Sections/S2-Partners";
import AboutSection from "../../Sections/S3-About";
import TeamsSection from "../../Sections/S11-Teams";
import Roadmap from "../../Sections/S6-Roadmap";
import Tokenomics from "../../Sections/S7-Tokenomics";
import FundSection from "../../Sections/S4-FundPlatform";
import TierSection from "../../Sections/S5-TierSystem";

const MainPage = (props) => {
  return (
    <PageWrapper>
      <HeroSection />
      <PageWrapper1>
        <Partner />
        <AboutSection />
      </PageWrapper1>
      <PageWrapper1>
      <FundSection />
      <TierSection />
      <Roadmap />
      </PageWrapper1>
      <PageWrapper1>
      <Tokenomics />
      <FAQSection />
      </PageWrapper1>
      <TeamsSection />
      <PageWrapper2>
      <Comunity />
      <Footer />
      </PageWrapper2> 
    </PageWrapper>
  );
};

export default MainPage;
