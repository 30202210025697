import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";
import user from "../../../assets/Images/user.png";
import msg from "../../../assets/Images/msg.png";
import robo from "../../../assets/Images/robo.png";
import {
  AbsoluiteImage,
  FlexGrid,
  GradiantDiv,
  Image,
} from "../../Styles/style";
const ContinerWraper = styled(Container)`
  width: 100%;
  padding: 50px 0 !important;
  @media (max-width: 599px){
    padding: 0px !important;
  }
`;

const AboutSection = () => {
  return (
    <ContinerWraper maxWidth="xxl">
      <Container>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} >
            <SectionsHeading>About</SectionsHeading>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Paragraph p="20px 0 30px">
              Alpha Fund aims to establish an ecosystem to benefit all holders.
              You don't need to feel like you are priced out of products like
              $TESO or can't ever catch the next 100x. With Alpha Fund even the
              little fish can become whales.
            </Paragraph>
          </Grid>
          <FlexGrid jc="space-between" container item xs={10}>
            <FlexGrid item xs={12} sm={4}>
              <GradiantDiv>
                <Image src={user} />
                <SectionsHeading fs="22px" fs599="22px">Holder</SectionsHeading>
                <Paragraph>
                  Get access to top signals and revenue share from copytrading
                  strategy.
                </Paragraph>
                <AbsoluiteImage
                
                  op=".1"
                  mw="40%"
                  t="100%"
                  l="-5%"
                  tran="translate(5%, -90%)"
                  src={user}
                />
              </GradiantDiv>
            </FlexGrid>
            <FlexGrid item xs={12} sm={4}>
              <GradiantDiv>
                <Image src={msg} />
                <SectionsHeading  fs="22px" fs599="22px">Bot Signals</SectionsHeading>
                <Paragraph>
                  Bot automatically sends calls whenever they are discovered.
                  Users simply monitor our private telegram channel for calls.
                  strategy.
                </Paragraph>
                <AbsoluiteImage
                  op=".1"
                  mw="40%"
                  t="100%"
                  l="-5%"
                  tran="translate(5%, -90%)"
                  src={msg}
                />
              </GradiantDiv>
            </FlexGrid>
            <FlexGrid item xs={12} sm={4}>
              <GradiantDiv>
                <Image src={robo} />
                <SectionsHeading  fs="22px" fs599="22px">Trading Bot</SectionsHeading>
                <Paragraph>
                  Our automated bot will ape calls with taxes earned and
                  distribute profits to holders periodically.
                </Paragraph>
                <AbsoluiteImage
                  op=".1"
                  mw="40%"
                  t="98%"
                  l="-5%"
                  tran="translate(5%, -90%)"
                  src={robo}
                />
              </GradiantDiv>
            </FlexGrid>
          </FlexGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default AboutSection;
