import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";
import fundImage from "../../../assets/Images/fundImage.png";
import {
  ColumnGrid,
  Image,
} from "../../Styles/style";
const ContinerWraper = styled(Container)`
  width: 100%;
  padding: 100px 0 !important;
`;


const FundSection = () => {
  return (
    <ContinerWraper maxWidth="xxl">
      <Container>
        <Grid container sx={{ display: "flex", justifyContent: "space-between" ,alignItems:"center"}}>
            <Grid item xs={12} sm={4}>
                <Image mw="100%" src={fundImage}/>
            </Grid>
            <ColumnGrid ai="start" item xs={12} sm={6}>
                <SectionsHeading>
                Alpha Fund Platform
                </SectionsHeading>
              <Paragraph p="20px 0 0" ta="left" fs="14px" fs599="14px">
                Discover top tier signals such as $PEPE, $UNIBOT, $BITCOIN,
                $GENIE, and more with our alpha signal channel. <br /><br />
              </Paragraph>
              <Paragraph p="0" color="#1279F3" ta="left" fs="16px" fs599="16px">
              0.5% Holders: 

              </Paragraph>
              <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                Get alerts from the top tools like TeSo, Metal Tools, Jungool,
                Bagheera, and more simply by holding 0.25% of the supply. Get
                access to those 100x calls you've been missing out on at a
                reduced cost! <br /><br />
              </Paragraph>
              <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                In addition, we will also distribute 60% of all profit from our
                copy trading strategy to holders with 0.5% of supply. <br /><br />
              </Paragraph>
              <Paragraph p="0"  color="#1279F3"  ta="left" fs="16px" fs599="16px">
              0.25% Holders:

              </Paragraph>
              <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                Get access to calls 5 minutes after 0.5% holders. In addition,
                you will earn 40% of all profits from our copy trading strategy. <br /><br />
              </Paragraph>
              <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                All others: Earn 10% of all revenue share from our copy trading
                strategy!
              </Paragraph>
            </ColumnGrid>
          </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default FundSection;
