import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";
import check from "../../../assets/Images/check-square.png";
import outlineblank from "../../../assets/Images/outline-blank.png";
import {
  ColumnGrid,
  FlexGrid,
  GradiantDiv,
  Image,
} from "../../Styles/style";
const ContinerWraper = styled(Container)`
  width: 100%;
  padding: 100px 0 !important;
  @media (max-width: 599px){
    padding-bottom: 0px !important ;
  }
`;

const InnerDiv = styled.div`
  background-color: ${(props) => props.theme.bgPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10px;
`;
const Flexdiv = styled.div`
  display: flex;
  width: 100%;
  column-gap: 10px;
  align-items: ${(props) => (props.ai ? props.ai : "start")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  margin: ${(props) => (props.m ? props.m : "0")};
`;

const Roadmap = () => {
  return (
    <ContinerWraper maxWidth="xxl">
      <Container>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} sx={{p:"30px 0"}}>
            <SectionsHeading >
              Roadmap
            </SectionsHeading>
          </Grid>
          <FlexGrid jc="space-between" container item xs={12} sm={10} md={8}>
            <ColumnGrid item xs={12} sm={6}>
              <InnerDiv>
                <SectionsHeading fm="PoppinsSB" fs="18px" fs599="16px">
                  Phase 1
                </SectionsHeading>
              </InnerDiv>
              <GradiantDiv br="0px 0px 5px 5px" m="0 0 20px" h="350px">
                <Flexdiv jc="start">
                  <Image mw="15px" src={check} />
                  <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                    Launch Friend.Tech account for presale round.
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="start">
                  <Image mw="15px" src={check} />
                  <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                    Initial marketing to bring attention to our product and
                    vision.
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="start">
                  <Image mw="15px" src={outlineblank} />
                  <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                    Raise Presale between FT Holders.
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="start">
                  <Image mw="15px" src={outlineblank} />
                  <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                    Launch token, alpha signals channel, and initiate copy
                    trading strategy.
                  </Paragraph>
                </Flexdiv>
              </GradiantDiv>
            </ColumnGrid>
            <ColumnGrid item xs={12} sm={6}>
              <InnerDiv>
                <Paragraph p="0" fm="PoppinsSB" fs="18px" fs599="14px">
                  Phase 2
                </Paragraph>
              </InnerDiv>
              <GradiantDiv br="0px 0px 5px 5px" m="0 0 20px" h="350px">
                <Flexdiv jc="start">
                  <Image mw="15px" src={outlineblank} />
                  <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                    Bring value to holders via calls and revenue share
                    distribution.
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="start">
                  <Image mw="15px" src={outlineblank} />
                  <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                    Implement ML to refine and improve copy trading strategy and
                    calls.
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="start">
                  <Image mw="15px" src={outlineblank} />
                  <Paragraph p="0" ta="left" fs="14px" fs599="14px">
                    Develop community DAO for incubation, research, networking,
                    and further development.
                  </Paragraph>
                </Flexdiv>
              </GradiantDiv>
            </ColumnGrid>
          </FlexGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default Roadmap;
