import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import xicon from "../../../../assets/Images/x-social-icon.png";
import telegram from "../../../../assets/Images/telegram.png";
import logo from "../../../../assets/Images/logo1.png";
import {
  MenuLink,
  MobileMainMenu,
  DrawerBox,
  DrawerDivider,
  Humburgger,
  HumburggerClose,
  MenuButton,
} from "../styles";
import { Image } from "../../../Styles/style";
import CustomButton from "../../../MainComponent/CustomBtn";

const MobileMenu = (props) => {
  const [state, setState] = useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("thest: ", anchor + " : " + open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  return (
    <MobileMainMenu>
      <MenuLink href="/" p="15px 0 15px">
        <Image src={logo} mw="50px" alt="Logo" />
      </MenuLink>
      <MenuButton onClick={toggleDrawer("left", true)}>
        {state["left"] ? (
          <HumburggerClose>Close</HumburggerClose>
        ) : (
          <Humburgger></Humburgger>
        )}
      </MenuButton>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <DrawerBox
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <DrawerDivider>
            <MenuLink href="/">
              <Image src={logo} mw="50px" alt="Logo" />
            </MenuLink>
            <Divider sx={{ background: "#1178F3" }} />
            <List>
              {props.menuList.map((value, i) => (
                <MenuLink
                  key={i}
                  href={value.link}
                  target={value.target}
                  className={value.customClass + " d-block"}
                >
                  {value.title}
                </MenuLink>
              ))}
            </List>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                columnGap:"10px"
              }}
            >
              <MenuLink>
                <Image mw="20px" src={xicon} />
              </MenuLink>
              <MenuLink>
                <Image mw="23px" src={telegram} />
              </MenuLink>
            </div>
            <div>
              <MenuLink>
                <CustomButton>Launch App</CustomButton>
              </MenuLink>
            </div>
          </DrawerDivider>
        </DrawerBox>
      </Drawer>
    </MobileMainMenu>
  );
};

export default MobileMenu;
