import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import LockerMenu from "../../Components/LockerMenu";
import heroHeading from "../../../assets/Images/heroHeading.png";
import heroImage from "../../../assets/Images/heroImage.png";
import { FlexGrid, Image } from "../../Styles/style";
import Paragraph from "../../MainComponent/Paragraph";
import CustomButton from "../../MainComponent/CustomBtn";

const CntainerWraper = styled(Container)`
    width: 100%;
    padding: 0 !important;
    background: radial-gradient(circle at 50% 0%, #1177f37a, transparent 80%);
`;
const GridWraper = styled(Grid)`
  display: grid;
  place-items: center;
  padding-top: 20px;
`;

const HeroSection = () => {
  return (
    <CntainerWraper maxWidth="xxl">
      <Container>
        <LockerMenu />
        <GridWraper container>
          <Grid item xs={12} sm={7}>
            <Image m="10px 0 0" mw="100%" src={heroHeading} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paragraph p="25px 0 30px">
              Get access to top signals directly through Telegram. Earn rewards
              from our advanced copy trading strategies.
            </Paragraph>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <FlexGrid jc="end" item xs={6}>
              <CustomButton mw="150px" hb>Getting Started</CustomButton>
            </FlexGrid>
            <FlexGrid jc="start" item xs={6}>
              <CustomButton mw="150px" hc="#fff" hbgc="#1178F3" bgc="transparent">Explore More</CustomButton>
            </FlexGrid>
          </Grid>
          <FlexGrid p="20px 0 0" item xs={12} sm={8}>
            <Image mw="100%" src={heroImage} />
          </FlexGrid>
        </GridWraper>
      </Container>
    </CntainerWraper>
  );
};

export default HeroSection;
