import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";
import copy from "../../../assets/Images/copy.png";
import linkicon from "../../../assets/Images/linkicon.png";
import whitpaper from "../../../assets/Images/whitpaper.png";
import audit from "../../../assets/Images/audit.png";
import {
  AbsoluiteImage,
  ColumnGrid,
  FlexGrid,
  GradiantDiv,
  Image,
} from "../../Styles/style";
import CustomButton from "../../MainComponent/CustomBtn";
const ContinerWraper = styled(Container)`
  width: 100%;
  /* padding: 100px 0 !important; */
  @media (max-width: 599px){
    padding: 100px 0 !important;
    }
`;

const InnerDiv = styled.div`
  background-color: ${(props) => props.theme.bgPrimary};
  display: flex;
  justify-content: ${props=>props.jc? props.jc : 'center'};
  align-items: center;
  width: 90%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 15px;
`;
const Flexdiv = styled.div`
  display: flex;
  width: 100%;
  column-gap: 10px;
  align-items: ${(props) => (props.ai ? props.ai : "start")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
`;

const Tokenomics = () => {
  return (
    <ContinerWraper maxWidth="xxl">
      <Container>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} sx={{p:"30px 0"}}>
            <SectionsHeading >
              Tokenomics
            </SectionsHeading>
          </Grid>
          <FlexGrid jc="space-between" container item xs={12}>
            <ColumnGrid item sm={12} md={6}>
              <InnerDiv jc='left'>
                <SectionsHeading fm="PoppinsSB" fs="18px" fs599="16px">
                  Main Information
                </SectionsHeading>
              </InnerDiv>
              <GradiantDiv br="0px 0px 5px 5px" m="0 0 20px" h="350px">
                <Flexdiv jc="space-between">
                  <Paragraph
                    p="0"
                    ta="left"
                    color="#ffffffa8"
                    fs="16px"
                    fs599="16px"
                  >
                    Contract Address
                  </Paragraph>
                  <Flexdiv jc="end">
                    <Paragraph
                      wb="break-all"
                      p="0"
                      ta="right"
                      fs="16px"
                      fs599="16px"
                    >
                      0Xxxxxxx00000000000000000
                    </Paragraph>
                    <Image mw="15px" src={copy} />
                  </Flexdiv>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph
                    p="0"
                    ta="left"
                    color="#ffffffa8"
                    fs="16px"
                    fs599="16px"
                  >
                    Name
                  </Paragraph>
                  <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                    Alpha Fund
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph
                    p="0"
                    ta="left"
                    color="#ffffffa8"
                    fs="16px"
                    fs599="16px"
                  >
                    Symbol
                  </Paragraph>
                  <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                    Alpha
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph
                    p="0"
                    ta="left"
                    color="#ffffffa8"
                    fs="16px"
                    fs599="16px"
                  >
                    Total Supply
                  </Paragraph>
                  <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                    1,000,000
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph
                    p="0"
                    ta="left"
                    color="#ffffffa8"
                    fs="16px"
                    fs599="16px"
                  >
                    Tax
                  </Paragraph>
                  <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                    5/5
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                    Tax enables us to continuously support long-term holders and
                    avoid quick pump and dumps, it will also be used for
                    marketing and our copy trading strategy.
                  </Paragraph>
                </Flexdiv>
              </GradiantDiv>
            </ColumnGrid>
            <ColumnGrid item sm={12} md={6}>
              <InnerDiv jc='left'>
                <Paragraph p="0" fm="PoppinsSB" fs="18px" fs599="14px">
                  Other Information
                </Paragraph>
              </InnerDiv>
              <GradiantDiv br="0px 0px 5px 5px" m="0 0 20px" h="350px">
                <Flexdiv jc="space-between">
                  <Paragraph
                    p="0"
                    ta="left"
                    color="#ffffffa8"
                    fs="16px"
                    fs599="16px"
                  >
                    Token Explorer
                  </Paragraph>
                  <Flexdiv jc="end">
                    <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                      Etherscan
                    </Paragraph>
                    <Image mw="15px" src={linkicon} />
                  </Flexdiv>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph
                    p="0"
                    ta="left"
                    color="#ffffffa8"
                    fs="16px"
                    fs599="16px"
                  >
                    Liquidity Locked
                  </Paragraph>
                  <Flexdiv jc="end">
                    <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                      Unicrypt
                    </Paragraph>
                    <Image mw="15px" src={linkicon} />
                  </Flexdiv>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph
                    p="0"
                    ta="left"
                    color="#ffffffa8"
                    fs="16px"
                    fs599="16px"
                  >
                    Contract Renounced
                  </Paragraph>
                  <Flexdiv jc="end">
                    <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                      Etherscan
                    </Paragraph>
                    <Image mw="15px" src={linkicon} />
                  </Flexdiv>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                    5% Of Tokens Locked For Marketing.
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                    5% Locked For Team (Vested 1 Year)
                  </Paragraph>
                </Flexdiv>
                <Flexdiv jc="space-between">
                  <Paragraph p="0" ta="left" fs="16px" fs599="16px">
                    Max TX/Wallet will be temporary at launch to prevent
                    dumping.
                  </Paragraph>
                </Flexdiv>
              </GradiantDiv>
            </ColumnGrid>
          </FlexGrid>
          <FlexGrid item xs={12} sm={6}>
            <GradiantDiv h="100px">
              <Flexdiv p="5px 0" jc="space-between">
                <Paragraph
                  p="0"
                  ta="left"
                  color="#1279F3"
                  fs="16px"
                  fs599="16px"
                >
                  Whitepaper
                </Paragraph>
              </Flexdiv>
              <Flexdiv p="5px 0" jc="start">
                <CustomButton
                  mw="150px"
                  bgc="transparent"
                  hbgc="#1178F3"
                  hc="#fff"
                >
                  Coming Soon
                </CustomButton>
              </Flexdiv>
              <AbsoluiteImage
                op=".4"
                mw="30%"
                t="100%"
                l="100%"
                tran="translate(-100%, -100%)"
                src={whitpaper}
              />
            </GradiantDiv>
          </FlexGrid>
          <FlexGrid item xs={12} sm={6}>
            <GradiantDiv h="100px">
              <Flexdiv p="5px 0" jc="space-between">
                <Paragraph
                  ta="left"
                  p="0"
                  color="#1279F3"
                  fs="16px"
                  fs599="16px"
                >
                  Audit
                </Paragraph>
              </Flexdiv>
              <Flexdiv p="5px 0" jc="start">
                <CustomButton
                  mw="150px"
                  bgc="transparent"
                  hbgc="#1178F3"
                  hc="#fff"
                >
                  Coming Soon
                </CustomButton>
              </Flexdiv>
              <AbsoluiteImage
                op=".4"
                mw="30%"
                t="100%"
                l="100%"
                tran="translate(-100%, -100%)"
                src={audit}
              />
            </GradiantDiv>
          </FlexGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default Tokenomics;
