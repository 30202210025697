import { Container, Grid, Link } from "@mui/material";
import React from "react";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";
import image1 from "../../../assets/Images/X.png";
import image2 from "../../../assets/Images/telegramBig.png";
import { FlexGrid, Image } from "../../Styles/style";
import styled from "@emotion/styled";

const Comunity = () => {
  const CntainerWraper = styled(Container)`
    width: 100%;
    padding: 100px 0 !important;
    background: radial-gradient(circle at 50% 0%, #1177f37a, transparent 50%);
  `;
  const Div = styled.div`
     margin: 0 20px;
  `
  return (
    <Container>
      <Container>
        <Grid container>
            <Grid item xs={12} sx={{p:"30px 0"}}>
                <SectionsHeading>
                Join The Community
                </SectionsHeading>
            </Grid>
          <FlexGrid item xs={12} fd='column'>
            <FlexGrid fd='column'>
              <Link
                href=""
                sx={{ textDecoration: "none", cursor: "pointer" }}
              >
                <Image mw="150px" src={image1} m="0 30px" />
              </Link>
              <div style={{ margin: "0 20px !important" }}>
                <SectionsHeading
                  ta="left"
                  fs="14px"
                  fs599="14px"
                  color="#1279F3 !important"
                >
                 Stay Up To Date.
                </SectionsHeading>
                <SectionsHeading ta="left" fs="24px" fs599="24px" fm="PoppinsR">
                  Loading...
                </SectionsHeading>
                <Paragraph p="0" fs="12px" ta="left">
                  Followers
                </Paragraph>
              </div>
            </FlexGrid>
            <FlexGrid fd='column'>
              <Link
                href=""
                sx={{ textDecoration: "none", cursor: "pointer" }}
              >
                <Image mw="150px" src={image2} m="0 30px" />
              </Link>
              <div style={{ m: "0 20px !important" }}>
                <SectionsHeading
                  ta="left"
                  fs="14px"
                  fs599="14px"
                  color="#1279F3 !important"
                >
                  24/7 Global Chat And Support.
                </SectionsHeading>
                <SectionsHeading ta="left" fs="24px" fs599="24px" fm="PoppinsR">
                  Loading...
                </SectionsHeading>
                <Paragraph p="0" fs="12px" ta="left">
                  Followers
                </Paragraph>
              </div>
            </FlexGrid>
          </FlexGrid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Comunity;
