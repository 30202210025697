import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import telegram1 from "../../../assets/Images/telegram1.png";
import linkicon from "../../../assets/Images/linkicon.png";
import {
  AbsoluiteImage,
  FlexGrid,
  GradiantDiv,
  Image,
} from "../../Styles/style";
const ContinerWraper = styled(Container)`
  width: 100%;
  padding: 100px 0 !important;
`;

const Flexdiv = styled.div`
  display: flex;
  width: 100%;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
`;

const TeamsSection = () => {
  return (
    <ContinerWraper maxWidth="xxl">
      <Container>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} sx={{p:"30px 0"}}>
            <SectionsHeading p="0 15px" p599="0 15px" p899="0 15px" ta="left">
              Team
            </SectionsHeading>
          </Grid>
          <FlexGrid jc="space-between" container item xs={12}>
            <FlexGrid item xs={12} sm={4}>
              <GradiantDiv h="100px">
                <Flexdiv jc="space-between">
                  <SectionsHeading
                    ta="left"
                    color="#1279F3"
                    fs="16px"
                    fs599="16px"
                  >
                    Co-Founder
                  </SectionsHeading>
                  <Image mw="15px" src={linkicon} />
                </Flexdiv>
                <Flexdiv jc="start">
                  <SectionsHeading ta="left" fs="16px" fs599="16px">
                    @Abc
                  </SectionsHeading>
                </Flexdiv>
                <AbsoluiteImage
                  op=".2"
                  mw="30%"
                  t="0%"
                  l="90%"
                  tran="translate(-90%, 0%)"
                  src={telegram1}
                />
              </GradiantDiv>
            </FlexGrid>
            <FlexGrid item xs={12} sm={4}>
              <GradiantDiv h="100px">
                <Flexdiv jc="space-between">
                  <SectionsHeading
                    ta="left"
                    color="#1279F3"
                    fs="16px"
                    fs599="16px"
                  >
                    Co-Founder
                  </SectionsHeading>
                  <Image mw="15px" src={linkicon} />
                </Flexdiv>
                <Flexdiv jc="start">
                  <SectionsHeading ta="left" fs="16px" fs599="16px">
                    @Abc
                  </SectionsHeading>
                </Flexdiv>
                <AbsoluiteImage
                  op=".2"
                  mw="30%"
                  t="0%"
                  l="90%"
                  tran="translate(-90%, 0%)"
                  src={telegram1}
                />
              </GradiantDiv>
            </FlexGrid>
            <FlexGrid item xs={12} sm={4}>
              <GradiantDiv h="100px">
                <Flexdiv jc="space-between">
                  <SectionsHeading
                    ta="left"
                    color="#1279F3"
                    fs="16px"
                    fs599="16px"
                  >
                    Co-Founder
                  </SectionsHeading>
                  <Image mw="15px" src={linkicon} />
                </Flexdiv>
                <Flexdiv jc="start">
                  <SectionsHeading ta="left" fs="16px" fs599="16px">
                    @Abc
                  </SectionsHeading>
                </Flexdiv>
                <AbsoluiteImage
                  op=".2"
                  mw="30%"
                  t="0%"
                  l="90%"
                  tran="translate(-90%, 0%)"
                  src={telegram1}
                />
              </GradiantDiv>
            </FlexGrid>
          </FlexGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default TeamsSection;
