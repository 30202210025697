import React from "react";
import xicon from "../../../../assets/Images/x-social-icon.png";
import telegram from "../../../../assets/Images/telegram.png";
import logo1 from "../../../../assets/Images/logo1.png";
import {
  DesktopMainMenu,
  MenuLink,
} from "../styles";
import CustomButton from "../../../MainComponent/CustomBtn";
import { Image } from "../../../Styles/style";

const DesktopMenu = (props) => {

  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/" p="0px">
        <Image src={logo1} mw="50px" alt="Logo" />
      </MenuLink>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.menuList.map((value, i) => (
          <MenuLink
            key={i}
            href={value.link}
            target={value.target}
            className={value.customClass}
          >
            {value.title}
          </MenuLink>
        ))}
        <MenuLink>
          <Image mw="20px" src={xicon} />
        </MenuLink>
        <MenuLink>
          <Image mw="23px" src={telegram} />
        </MenuLink>
        <MenuLink>
          <CustomButton>Launch App</CustomButton>
        </MenuLink>
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
