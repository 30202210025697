import React from "react";
import { Container, Grid } from "@mui/material";
import { Div, Image } from "../../Styles/style";
import metamask from "../../../assets/Images/metamask.png";
import trustwallet from "../../../assets/Images/trustwallet.png";
import dextools from "../../../assets/Images/dextools.png";
import dexscrener from "../../../assets/Images/dexscrener.png";
import uniswap from "../../../assets/Images/uniswap.png";
import etherscan from "../../../assets/Images/etherscan.png";
import styled from "@emotion/styled";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import image from "../../../assets/Images/aboutImage.png";
import { AbsoluiteImage } from "../../Styles/style";
import Paragraph from "../../MainComponent/Paragraph";

const InnerGrid = styled(Grid)`
  background: #00000083;
  box-shadow: 0px 0px 2px #1277f396;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  border-radius: 5px;
  padding: 10px 10px 50px 10px;
  margin: 10px 0;
  position: relative;
  overflow: hidden;
  min-height: 150px;
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: grayscale(100%);
    border-radius: 20px;
  }
`;
const InnerDiv = styled.div`
  background-color: ${(props) => props.bgPrimary};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 50%;
  border-radius: 20px;
  padding: 10px;
  @media(max-width: 599px){
    width : 100%;
  }
`;
const ImageGrid = styled(Grid)`
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: 899px) {
    flex-direction: column;
  }
`;

const ContinerWraper1 = styled(Container)`
  /* background: radial-gradient(circle at 50% 60%, #1177f37a, transparent 50%); */
  width: 100%;
  padding: 100px 0;
  margin-top: -4%;
  @media (max-width: 599px){
    padding-bottom: 0px;
  }
`;

const Partner = () => {
  return (
    <>
      <ContinerWraper1>
        <Grid container>
          <Grid item xs={12}>
            <SectionsHeading p="30px 0">Our Partners</SectionsHeading>
          </Grid>
          <ImageGrid item xs={12} md={12}>
            <Div
              fd899="column"
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              <Image mw="120px" src={metamask} />
              <Image mw="120px" src={trustwallet} />
              <Image mw="90px" src={dextools} />
              <Image mw="120px" src={dexscrener} />
              <Image mw="120px" src={uniswap} />
              <Image mw="120px" src={etherscan} />
            </Div>
          </ImageGrid>
        </Grid>
        <Container sx={{ mt: "110px", padding: "0 !important" }} id="stats">
          <Grid
            container
            sx={{
              columnGap: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InnerGrid bg="" item xs={12}>
              <InnerDiv>
                <SectionsHeading ta="left" >
                  Alpha Platform
                </SectionsHeading>
                <SectionsHeading
                  p="10px 0"
                  p599="10px 0"
                  p899="10px 0"
                  ta="left"
                  fs="16px"
                  fs599="16px"
                  color="#1279F3 !important"
                >
                  A Crypto Alpha Platform Backed By The ALPHA Token
                </SectionsHeading>
                <Paragraph ta="left">
                  Get access to top tier signals that combine on-chain analysis
                  with social analysis. Alpha Fund aggregates calls from the top
                  signal bots and democratizes access to more holders.
                </Paragraph>
              </InnerDiv>
              <AbsoluiteImage
              style={{filter:"drop-shadow(0px 0px 40px #d7b89054)"}}
                mw="260px"
                l="104%"
                t="30%"
                l599="50%"
                t599="50%"
                op599=".3"
                tran="translate(-104%,-30%)"
                tran599="translate(-50%,-50%)"
                src={image}
              />
            </InnerGrid>
          </Grid>
        </Container>
      </ContinerWraper1>
    </>
  );
};

export default Partner;
