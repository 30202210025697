import React from 'react'
import DesktopMenu from './component/DesktopMenu';
import MobileMenu from './component/MobileMenu';

const LockerMenu = (props) => {
    const menuList = [ // You can pass 4 perameters for menu title, link, target and customClass
        {
        title: "About",
        link: "#about",
        },
        {
        title: "Application Overview",
        link: "#Overview",
        },
        {
        title: "Utility",
        link: "#utility",
        },
        {
        title: "WhitePaper",
        link: "",
        customClass: "",
        target: "_blank"
        }, 
        
    ];
  return (
    <>
        <DesktopMenu menuList={menuList} />
        <MobileMenu menuList={menuList} />
    </>
  )
}

export default LockerMenu